var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Rendez-vous acquéreur ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('p',[_vm._v(" Visualisez et gérez ici les rendez-vous de "),_vm._l((_vm.sale.buyers),function(buyer,i){return _c('span',{key:buyer.id + '_' + buyer.type,staticClass:"blue--text"},[_vm._v(_vm._s(buyer.name)+" "+_vm._s(buyer.firstname)),(i < _vm.sale.buyers.length - 1)?[_vm._v(" / ")]:_vm._e()],2)}),_vm._v(", "+_vm._s(_vm._f("plural")(_vm.sale.buyers.length,'acquéreur', 'acquéreurs'))+" "),_c('span',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.sale.product.reference)+" ")]),_vm._v(" sur le programme "),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.sale.program.title))]),_vm._v(". ")],2),_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Afficher les rendez-vous passés","dense":"","hide-details":""},model:{value:(_vm.displayPastAppointments),callback:function ($$v) {_vm.displayPastAppointments=$$v},expression:"displayPastAppointments"}}),_c('v-spacer'),_c('ManageAppointmentDialog',{attrs:{"sale":_vm.sale},on:{"created":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":""}},on),[_vm._v(" Nouveau rendez-vous ")])]}}])})],1),_c('ValidationObserver',{ref:"observer"},[_c('v-simple-table',{staticClass:"rounded"},[[_c('thead',[_c('tr',[_c('th',[_vm._v(" Object ")]),_c('th',[_vm._v(" Date, heure, durée ")]),_c('th',[_vm._v(" Lieu ")]),_c('th',[_vm._v(" Intervenant / Compétences ")]),_c('th')])]),_c('tbody',[_vm._l((_vm.appointments),function(appointment){return _c('tr',{key:appointment.id},[_c('td',[_vm._v(" "+_vm._s(appointment.subject)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getAppointmentDateText(appointment))+" "),(appointment.instance && [11, 12].includes(appointment.instance.status))?[_c('br'),_c('span',_vm._g({class:_vm.getAppointmentStatusTextColor(appointment.instance.status) + ' font-weight-medium'},_vm.on),[_vm._v(" "+_vm._s(_vm.getAppointmentStatusLabel(appointment.instance.status))+" ")])]:_vm._e(),(_vm.isAppointmentExpired(appointment))?[_c('br'),_c('v-chip',{attrs:{"label":"","x-small":""}},[_vm._v(" Expiré ")])]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(appointment.location)+" ")]),_c('td',[_vm._v(" "+_vm._s(appointment.categories.map(function (c) { return c.name; }).join(', '))+" ")]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('ManageAppointmentDialog',{attrs:{"sale":_vm.sale,"appointmentId":appointment.id},on:{"updated":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"app-blue","small":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Modifier ")])]}}],null,true)}),(appointment.instance && appointment.instance.status === 0)?_c('CancelAppointmentAdminDialog',{attrs:{"appointment":appointment},on:{"canceled":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"red","small":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-ban ")])],1)]}}],null,true)},[_vm._v(" Annuler ")])]}}],null,true)}):_vm._e(),(!appointment.instance || appointment.instance.status !== 0)?_c('ConfirmationDialog',{attrs:{"text":"Êtes-vous sûr de vouloir supprimer ce rendez-vous ?"},on:{"confirm":function($event){return _vm.deleteAppointment(appointment)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"red","small":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,true)}):_vm._e()],1)])])}),(_vm.appointments.length === 0)?_c('tr',[_c('td',{staticClass:"text-center grey--text",attrs:{"colspan":"5"}},[_vm._v(" Aucun rendez-vous ")])]):_vm._e()],2)]],2)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }